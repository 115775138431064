import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import TablePagination, { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';



import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import { Localization } from "material-table";



import { Learner } from '../../react-app-env';
import * as common from "../../common";
import { transitLearnerValue } from '../Learner/Index';
import {CustomModal} from "../StylesUI/CustomModal"
import { JsxElement } from 'typescript';



// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    learnerTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        "& .MuiTableCell-root": {
            fontSize: "1.2rem"
        },
        "& .MuiTableCell-head": {
            fontSize: "1.3rem"
        },
        "& .MuiTableCell-alignRight svg": {
            fontSize: "16px"
        },
        "& .MuiIconButton-label svg": {
            fontSize: "20px"
        },
        "& .MuiTableRow":{
            "&:hover": {
                backgroundColor: "rgba(245, 0, 87, 0.08)"
            },
            cursor: "pointer"
        }
    },
    columnName: {
        backgroundColor: "#7c7c80",
        opacity: 0.8,
        height: "60px"
    },
    columnNameStyle: {
        fontWeight: "bold",
        color: "#ffffff"
    },
    ordercolumnStyle: {
        fontWeight: "bold",
        color: "#ffffff",
        cursor: "pointer",
        "& span": {
            display: "inline-block",
            verticalAlign: "middle",
        }
    },
    checkBoxColumnStyle: {
        borderRadius: 0,
        backgroundColor: "white",
        padding: 0,
        marginLeft: 9
    },
    pageNation: {
        "& .MuiTypography-body2 ,.MuiTablePagination-input ,.MuiTablePagination-root": {
            fontSize: common.FONT_SIZE.tablePagenation
        }
    },
    contextMenu: {
        "& .MuiMenuItem-root": {
            fontSize: common.FONT_SIZE.tableBody,
        }
    },
    hoverRowStyle: {
        "&:hover": {
            backgroundColor: "rgba(245, 0, 87, 0.08)"
        },
        cursor: "pointer"
    },
    modalText: {
        fontSize:"2rem",
        fontWeight:"bold",
        padding:"2rem 0",
        textAlign: "center",
        whiteSpace: "pre"
    }
}));

//material-tableの日本語化変数
const localizationData: Localization = {
    error: "エラー",
    body: {
      emptyDataSourceMessage: "表示するレコードがありません。",
      filterRow: {
        filterTooltip: "フィルター",
      },
      editRow: {
        saveTooltip: "保存",
        cancelTooltip: "キャンセル",
        deleteText: "この行を削除しますか？",
      },
      addTooltip: "追加",
      deleteTooltip: "削除",
      editTooltip: "編集",
    },
    header: {
      actions: "",
    },
    grouping: {
      groupedBy: "グループ化:",
      placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
      firstTooltip: "最初のページ",
      firstAriaLabel: "最初のページ",
      previousTooltip: "前のページ",
      previousAriaLabel: "前のページ",
      nextTooltip: "次のページ",
      nextAriaLabel: "次のページ",
      labelDisplayedRows: "{from}-{to} 全{count}件",
      labelRowsPerPage: "ページあたりの行数:",
      lastTooltip: "最後のページ",
      lastAriaLabel: "最後のページ",
      labelRowsSelect: "行",
    },
    toolbar: {
      addRemoveColumns: "列の追加、削除",
      nRowsSelected: "{0} 行選択",
      showColumnsTitle: "列の表示",
      showColumnsAriaLabel: "列の表示",
      exportTitle: "出力",
      exportAriaLabel: "出力",
      searchTooltip: "検索",
      searchPlaceholder: "受験番号検索",
    }
  };



const initialState = {
    mouseX: null,
    mouseY: null,
};

type orderFunc = (learnerArray: Learner[]) => Learner[]

interface Props {
    learnerData: Learner[];
    examId: string;
    examName: string;
    sendNotification: any;
    switchOrder: orderFunc;
    //handleClickSortOrder: any;
    //sortOrder: any;
    rerenderCompnent: any;
    learnerNumSort: any;
    acceptSort: any;
    noticeSort: any;
    executeDateSort: any;
    executeStartSort: any;
    executeEndSort: any;
}

interface tableRecord {
    learnerNum: string;
    userName: string;
    accept_DateTime:string;
    notice: string;
    executeDate:string;
    executeStart: string;
    executeEnd: string;
    recording: string;
    checkResult: string;
    checkUser: string;
    tableData: { checked: boolean } 
    accept: string;
    notice_DateTime: string;
    executeDate_DateTime: string;
    executeStart_DateTime: string;
    executeEnd_DateTime: string;
}




export function LearnerTable(props: Props) {
    // -----定数の定義-----
    const learnerData: Learner[] = props.learnerData ?? [];


    // -----共通関数の宣言-----
    const {
        api,
        params,
        go // 画面遷移 
    } = common.useCommon();


    // 受験者データを削除する
    function deleteLearnerData(args?: any) {
        return api("/api/o-learner", "DELETE", args)
    }



    // -----Handler-----

    const handleClose = () => {
        setState(initialState);
    };

    //テーブルを左クリック時の処理
    const handleNormalClick = (event: any, learner:tableRecord | undefined) => {
        console.log(learner);
        if( event !== undefined ) event.preventDefault();
        setState(initialState);
        if ( learner !== undefined && selectedLearners.includes(learner.userName) ) {
            removeLearnerSelected(learner.userName);
            return;
        } else if(learner !== undefined){
            addLearnerSelected(learner.userName);
        }
    };

    //チェックボックスクリック時の処理
    const handleCheckboxClick =(selectedLearners:tableRecord[] ,learner:tableRecord | undefined)=>{
        const selectedLearnersArray = selectedLearners.map( learner => learner.userName );
        setSelectedLearners(selectedLearnersArray);
    }


    const addLearnerSelected =(userName:string)=>{
        const selectedLearnersArray = selectedLearners.concat();
        selectedLearnersArray.push(userName);
        setSelectedLearners(selectedLearnersArray);
    }


    const removeLearnerSelected =(userName:string)=>{
        const selectedLearnersArray = selectedLearners.concat();
        var val = userName
        var idx = selectedLearnersArray.indexOf(val);
        if(idx >= 0){
            selectedLearnersArray.splice(idx, 1); 
        }
        
        setSelectedLearners(selectedLearnersArray);
    }


    const handleClickContextMenu = (event:any ,learner:tableRecord)=>{
        if(!selectedLearners.includes(learner.userName)) addLearnerSelected(learner.userName);

        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });

    }




    // -----汎用関数定義-----

    //編集ページへの遷移時のJSONデータ返す
    function getTransitionInfo(): transitLearnerValue {
        const selectedLeaner = getSelectedLearnerData(selectedLearners[0])
        const info = {
            pathname: "/Learner/edit/" + params.examId + "/" + selectedLeaner.userName,
            state: {
                userName: selectedLeaner.userName,
                executionId: Number(props.examId),
                learnerNumber: selectedLeaner.learnerNumber,
                email: selectedLeaner.email,
                acceptDatetime: selectedLeaner.acceptDatetime,
                noticeDatetime: selectedLeaner.noticeDatetime,
                executionStartDatetime: selectedLeaner.executionStartDatetime,
                executionEndDatetime: selectedLeaner.executionEndDatetime,
                checkDate: selectedLeaner.checkDate,
                checkResult: selectedLeaner.checkResult,
                modified: selectedLeaner.modified,
                checkUser: selectedLeaner.checkUser,
                deleteFlag: selectedLeaner.deleteFlag
            }
        };
        return info;
    }


    const goEdit = () => {
        go(getTransitionInfo())
    }

    

    const goRecordingPlayback = () => {
        const info = {
            pathname: "/recording-playback/" + props.examId + "/" + selectedLearners[0] + '/0',
            state: {
                examName: props.examName,
                learnerNum: getSelectedLearnerData(selectedLearners[0]).learnerNumber
            }
        };

        go(info);
    }



    //受験者削除時、APIの引数用にデータを変換
    const convertDeleteArgs = (learnerData: Learner) => {
        return {
            userName: learnerData.userName,
            executionId: learnerData.executionId,
            learnerNumber: learnerData.learnerNumber,
            email: learnerData.email,
            checkResult: learnerData.checkResult,
            checkUser: learnerData.checkUser,
            modified: learnerData.modified,
            deleteFlag: learnerData.deleteFlag
        }
    }


    

    function deleteSelectedLearners() {

        let count = 0;

        for (const userName of selectedLearners) {
            const learnerData = getSelectedLearnerData(userName);
            deleteLearnerData(convertDeleteArgs(learnerData))
                .then((data) => {
                    if (data.errorCode !== 20000) {
                        common.alertError(data.errorTitle, data.errorDetail);
                        return;
                    }

                    count++;
                    if (count >= selectedLearners.length) {
                        handleNoticeModalOpen(common.ResponseMessages.Sucess_DeleteLearner ,props.rerenderCompnent);
                        setSelectedLearners([]);
                    }

                }).catch((err) => {
                    alert(common.ResponseMessages.Error_DeleteLearner);
                    return;
                });
        }
    }


    //引数の受験者IDのレコードが選択されているかどうか
    const isSelectedLearner = (userName:string)=>{
        if (selectedLearners.includes(userName)) {
            return true;
        } else {
            return false;
        }
    }

    //引数の受験者IDのLearnerデータを返す
    const getSelectedLearnerData = (userName:string)=>{
       const retValLearnerArray = learnerData.filter( (learner:Learner) => (learner.userName === userName)  );
       return retValLearnerArray[0];
    }


    /*
    const sortDateTime = (stringDateTimeA:string | null ,stringDateTimeB:string | null)=>{
        if( stringDateTimeA === null ) return -1;
        if( stringDateTimeB === null ) return 1;
        const dateTImeA = Date.parse(stringDateTimeA);
        const dateTImeB = Date.parse(stringDateTimeB);
        return dateTImeA - dateTImeB;
    }

    const sortLearnerNum = (learnerNumA: string | null, learnerNumB: string | null) => {
      if (learnerNumA === null) return -1;
      if (learnerNumB === null) return 1;
      const sorted = [learnerNumA, learnerNumB].sort();
      return sorted[0] === learnerNumA ? -1 : 1;
    }
    */

    function sendNotificationIfNeeded() {
        const massage = "通知の送信には時間がかかる場合があります。\n選択したユーザに通知の送信を開始してもよろしいでしょうか？";
        handleModalOpen(massage, () => sendNotification());
    }

    function sendNotification(){
        props.sendNotification(selectedLearners)
        .then((result:JsxElement)=>{

            handleNoticeModalOpen(result ,()=>{});

        }).catch((err:any) => {
            alert(err);
        }); 
        
    }

    function handleModalOpen(modalText:string ,modalFunc:any){
        setIsNotice(false);
        setModalFunction(()=>modalFunc);
        setModalText(modalText);
        setOpenModal(true);
        handleClose();
    }

    function handleNoticeModalOpen(modalText:string | JsxElement ,modalFunc:any){
        setIsNotice(true);
        setModalFunction(()=>modalFunc);
        setModalText(modalText);
        setOpenModal(true);
    }

    // -----スタイルの宣言-----
    const classNames = useStyles();

    



    // -----state-----
    const [state, setState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);
    //選択データのlearneIdとLearner[]内での添え字
    const [selectedLearners, setSelectedLearners] = React.useState<string[]>([]);
    //モーダル関係
    const [openModal, setOpenModal] = React.useState(false);
    const [isNotice, setIsNotice] = React.useState(false);
    const [modalText, setModalText] = React.useState<string | JsxElement>("");
    const [modalFunction, setModalFunction] = React.useState<any>(()=>{});




    return (
        <div className={classNames.learnerTableRoot} >

            <CustomModal
                openModal = {openModal}
                setOpenModal = {setOpenModal}
                modalFunc = {modalFunction}
                isNotice = {isNotice}
            >
                <div className={classNames.modalText}>{modalText}</div>
            </CustomModal>
           
            <MaterialTable
                icons={ {
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                columns={[
                  { title: '受験番号', field: 'learnerNum', cellStyle: { fontWeight: "bold" }, type: "numeric", align: "left"/*, defaultSort: "asc"*/, customSort: () => props.learnerNumSort() },
                  { title: 'メールアドレス', field: 'email' ,type:"string" ,sorting: false ,searchable:false },
                  { title: '受付', field: 'accept', customSort: () => props.acceptSort() /*(a, b) => sortDateTime(a.accept_DateTime,b.accept_DateTime)*/, searchable: false },
                  { title: '通知', field: 'notice', customSort: () => props.noticeSort() /*(a, b) => sortDateTime(a.notice_DateTime,b.notice_DateTime)*/, searchable: false },
                  { title: '実施日', field: 'executeDate', customSort: () => props.executeDateSort() /*(a, b) => sortDateTime(a.executeStart_DateTime,b.executeStart_DateTime)*/, searchable: false },
                  { title: '開始時刻', field: 'executeStart', customSort: () => props.executeStartSort() /*(a, b) => sortDateTime(a.executeStart_DateTime,b.executeStart_DateTime)*/, searchable: false },
                  { title: '終了時刻', field: 'executeEnd', customSort: () => props.executeEndSort() /*(a, b) => sortDateTime(a.executeEnd_DateTime,b.executeEnd_DateTime)*/, searchable: false },
                  { title: '録画管理', field: 'recording' ,sorting: false ,searchable:false},
                  { title: '不正判定', field: 'checkResult' ,sorting: false ,searchable:false},
                  { title: '確認者', field: 'checkUser' ,sorting: false ,searchable:false},
                ]}
                data={
                  props.switchOrder(learnerData).map((data, idx) => (
                    { learnerNum: data.learnerNumber, 
                      email: data.email,
                      userName: data.userName, 
                      accept: data.acceptDatetime === null ? "未受付" : common.dateFormat(data.acceptDatetime, "LLL"), 
                      notice: data.noticeDatetime === null ? "未通知" : common.dateFormat(data.noticeDatetime, "LLL"), 
                      executeDate: data.executionStartDatetime === null ? "未実施" : common.dateFormat(data.executionStartDatetime, "LL"), 
                      executeStart: data.executionStartDatetime === null ? "" : common.dateFormat(data.executionStartDatetime, "LT"), 
                      executeEnd: data.executionEndDatetime === null ? "" : common.dateFormat(data.executionEndDatetime, "LT"), 
                      recording: data.checkDate === null ? "" : data.checkDate, 
                      checkResult: data.checkResult, 
                      checkUser: data.checkUser, 
                      tableData: { checked: isSelectedLearner(data.userName) }, 
                      /*accept_DateTime: data.acceptDatetime,
                      notice_DateTime: data.noticeDatetime,
                      executeDate_DateTime: data.executionStartDatetime,
                      executeStart_DateTime: data.executionStartDatetime,
                      executeEnd_DateTime: data.executionEndDatetime,*/
                    }
                  ))
                }
                actions={[
                    {
                      icon: () => <MoreHorizIcon />,
                      tooltip: 'メニューを開く',
                      onClick: (event, rowData:any) => handleClickContextMenu(event,rowData),
                      position: "row"
                    },
                    {
                      icon: () => <DeleteOutline style={{fontSize:"2.5rem"}} color="secondary"/> ,
                      tooltip: '選択中の受験者を削除',
                      disabled: selectedLearners.length < 1,
                      onClick: () => handleModalOpen("選択中の受験者を削除します",deleteSelectedLearners)
                    }
                ]}
                onSelectionChange={(selectedRecord,rowData) => {
                    handleCheckboxClick(selectedRecord,rowData);
                }}
                onRowClick={(event,rowData) => {
                    handleNormalClick(event,rowData);
                }}
                options={{
                    actionsColumnIndex: -1,
                    toolbarButtonAlignment:"left",
                    showTextRowsSelected:false,
                    showTitle: false,
                    selection: true,
                    //filtering: true,
                    searchFieldStyle: {
                      marginRight: 16
                    },
                    draggable: false,
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight:"bold"
                    }
                }}
                localization={localizationData}
            />


            <Menu
                className={classNames.contextMenu}
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                <MenuItem disabled={selectedLearners.length !== 1} onClick={goEdit}>受験者の編集</MenuItem>
                <MenuItem onClick={sendNotificationIfNeeded}>通知送信</MenuItem>
                <MenuItem disabled={selectedLearners.length !== 1} onClick={goRecordingPlayback}>録画再生</MenuItem>
                <MenuItem disabled={selectedLearners.length < 1} onClick={()=>handleModalOpen("選択中の受験者を削除します",deleteSelectedLearners)}>選択中の受験者を削除</MenuItem>
            </Menu>


        </div>
    );
}
