import { AppBar, BottomNavigation, BottomNavigationAction, Grid, Paper, Tab, Tabs, TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from '@material-ui/core/Slider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import React from 'react';
import { createUseStyles } from 'react-jss';
import * as common from "../../common";
import { useInterval } from '../../common';
import { APIResponse, Cheat, CheckCheat, Exam, Learner } from '../../react-app-env';
import { Layout } from '../Layout';
import { normalLinks } from '../NavLinks/NavLinks';
import { ButtonContainer, DefaultBackButton, DefaultButton } from '../StylesUI/CommonLayouts';
import { CustomModal } from '../StylesUI/CustomModal';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    recordingPlaybackRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h6": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom: "0px"
        }
    },
    bottomNavigation: {
        "& .MuiBottomNavigationAction-label": {
            color: "black",
            fontSize: 20,
        },
        "& .MuiSvgIcon-root": {
            width: "2em",
            height: "2em"
        }
    },
    upperElements: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        marginBottom: "1.6rem",
        "& #learnerNum": {
            marginRight: "2.4rem",
            padding: "0.8rem 3.2rem",
            borderRadius: " 0.8rem",
            backgroundColor: "#00468b",
            color: "#ffffff",
            whiteSpace: "nowrap",
            fontSize: common.FONT_SIZE.title,
            fontWeight: "bold"
        },
        "& #examName": {
            color: "#00468b",
            fontSize: "1.8rem",
            fontWeight: "bold"
        }
    },
    learnerListContainer: {
        position: "relative"
    },
    links: {
        //position: "absolute",
        top: "-24px"
    },
    recordingPlayer: {
        width: "100%",
        height: "200px",
        border: "solid 5px"
    },
    multilineColor: {
        background: 'white'
    },
    boxNoCheat: {
        borderColor: "green"
    },
    boxMaybeCheat: {
        borderColor: "yellow"
    },
    boxCheat: {
        borderColor: "red"
    },
    textField: {
        "& > div": {
            background: "white",
            borderRadius: "0.8rem"
        }
    },
    buttonStyle: {
        display: "flex",
        justifyContent: "start",
        marginTop: "1.2rem",
        marginBottom: "2.4rem",
        "& button": {
            marginRight: "1.8rem"
        },
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    remarkText: {
        "& .MuiFormControl-root": {
            borderRadius: "0.8rem"
        }
    }
});


export interface transitRecordingValue {
    examName: string
};

export interface transitPram {
    pathname: string,
    state: transitRecordingValue
};

const PAGE_SIZE = 6;
const TAB_INDEX_PHOTO = 0;
const TAB_INDEX_ENV_RECORD = 1;
const TAB_INDEX_RECORD = 2;

export function RecordingPlaybackMulti() {

    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();

    const classNames = useStyles();

    // ----- states
    const [examState, setExamState] = React.useState({ name: "", timeSec: 0 });
    const [examLearners, setExamLearners] = React.useState([] as Learner[]);
    const [checkCheats, setCheckCheats] = React.useState([] as CheckCheat[]);
    const [cheats, setCheats] = React.useState([] as Cheat[]);
    const [state, setState] = React.useState({
        pageIndex: 0,
        tabIndex: TAB_INDEX_PHOTO,
        data: [] as { src: string | undefined; learner: Learner }[]
    });
    const [sliderData, setSliderData] = React.useState({
        value: 0,
        max: 0,
        volume: 0,
        isPlayng: false
    });
    const [isChangeCell, setIsChangeCell] = React.useState(false);

    // -----API-----
    // 受験者データを取得する
    function getLearnerData(args?: any): Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    function getExamData(args?: any): Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    async function getMovieUrl(userName: string, isEnv = false) {
        var res: APIResponse<{ sas: string; expired: string }> =
            await api("/api/o-storage", "GET", {
                userName,
                isEnv
            });
        return res.value;
    }

    async function getCheats() {
        var res: APIResponse<Cheat[]> =
            await api("/api/o-cheat", "GET");
        return res;
    }

    async function getCheckCheats(executionId: number) {
        var res: APIResponse<CheckCheat[]> =
            await api("/api/o-learnerCheck", "GET", { executionId });
        return res.value;
    }

    function setTabData(
        tabIndex: number,
        pageIndex: number) {
        // examLearnersの最新の値を利用できるように
        // コールバックで全体を囲む 
        setExamLearners(_examLearners => {
            // indexの範囲チェック
            if (pageIndex < 0
                || (_examLearners.length / PAGE_SIZE) <= pageIndex) {
                return _examLearners;
            }
            const examId = parseInt(params.examId);
            getCheckCheats(examId)
                .then((v) => {
                    setCheckCheats(x => v ?? []);
                });
            const promises = _examLearners.filter((x, index) => {
                // page = 0, index = 0 -> 5
                // page = 1, index = 6 -> 11
                return PAGE_SIZE * pageIndex <= index
                    && index < PAGE_SIZE * (pageIndex + 1);
            }).map(async _learner => {
                switch (tabIndex) {
                    case TAB_INDEX_PHOTO:
                        // 本人
                        return Promise.resolve({
                            learner: _learner,
                            src: _learner.portrait
                        });
                        break;
                    case TAB_INDEX_ENV_RECORD:
                        // 会場
                        // アクセス権付きの一時URLをユーザー名を引数に取得
                        const sasData = await getMovieUrl(_learner.userName, true);
                        return {
                            learner: _learner,
                            src: sasData == null ? "" : sasData.sas
                        };
                        break;
                    case TAB_INDEX_RECORD:
                        // 受験
                        // アクセス権付きの一時URLをユーザー名を引数に取得
                        const sasData_1 = await getMovieUrl(_learner.userName);
                        return {
                            learner: _learner,
                            src: sasData_1 == null ? "" : sasData_1.sas
                        };
                        break;
                    default:
                        return Promise.resolve({
                            learner: _learner,
                            src: ""
                        });
                }
            });
            Promise.all(promises).then((data) => {

                // maxを設定する
                const videos = document.querySelectorAll(`video.${classNames.recordingPlayer}`);
                videos.forEach((x) => {
                    const v = x as HTMLVideoElement;
                    v.onloadedmetadata = (e) => {
                        setSliderData(s => {
                            // console.log(v.duration);
                            if (isFinite(v.duration) && s.max < v.duration) {
                                return {
                                    ...s,
                                    max: v.duration
                                };
                            }
                            return s;
                        });
                    };
                });

                setState((s) => {
                    return {
                        pageIndex,
                        tabIndex,
                        data
                    };
                });

                // 現在の再生位置をリセット
                setSliderData((x) => {
                    return {
                        isPlayng: false,
                        value: 0,
                        volume: 0,
                        max: x.max
                    }
                });
            });
            return _examLearners;
        });
    }

    // 初期化関数
    async function init() {
        // サーバーから認証してデータ取得
        try {
            const resLearners = await getLearnerData({ executionId: params.examId });
            if (resLearners.errorCode !== 20000) {
                common.alertError(resLearners.errorTitle, resLearners.errorDetail);
                return;
            }
            // 受験番号ソート
            resLearners.value.sort(function (a: Learner, b: Learner) {
              return a.learnerNumber.localeCompare(b.learnerNumber);
            });
            setExamLearners(s => resLearners.value);
            const pageIndex = parseInt(params.pageIndex);
            setTabData(Number(params.tabIndex), isFinite(pageIndex) ? pageIndex : 0);
        } catch (err) {
            alert(common.ResponseMessages.Error_GetLearner);
        }
        // 不正マスタを取得
        try {
            const resCheats = await getCheats();
            if (resCheats.errorCode !== 20000) {
                common.alertError(resCheats.errorTitle, resCheats.errorDetail);
                return;
            }
            if (resCheats.value !== null) {
                setCheats(resCheats.value);
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetExam);
        }
        // 試験名を取得
        try {
            const resExam = await getExamData({ id: params.examId });
            if (resExam.errorCode !== 20000) {
                common.alertError(resExam.errorTitle, resExam.errorDetail);
                return;
            }
            if (resExam.value !== null) {
                setExamState({ name: resExam.value[0].name, timeSec: resExam.value[0].executeTime * 60 });
            }
        } catch (err) {
            alert(common.ResponseMessages.Error_GetExam);
        }
    }

    React.useEffect(() => {
        init();
    }, [params.examId, params.pageIndex, params.tabIndex]);

    React.useEffect(() => {
        setIsChangeCell(false);
        init();
    }, [isChangeCell]);

    React.useEffect(() => {
        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, [])

    useInterval(() => { }, () => {
        let currentMax = 0;
        const videos = document.querySelectorAll(`video.${classNames.recordingPlayer}`);
        videos.forEach((x) => {
            const v = x as HTMLVideoElement;
            if (!v.src) {
                return;
            }
            if (v.currentTime >= currentMax) {
                currentMax = v.currentTime;
            }
        });
        setSliderData((s) => {
            // 最後まで来たか判定
            if (currentMax >= Math.floor(sliderData.max)) {
                // 最後前来ていたら全部ストップしてゼロ位置に。
                videos.forEach((x) => {
                    const v = x as HTMLVideoElement;
                    v.pause();
                    v.currentTime = 0;
                });
                return {
                    ...s,
                    value: 0,
                    isPlayng: false
                }
            } else {
                return {
                    ...s,
                    value: currentMax
                };
            }
        });
    }, 1000);

    return (
        <Layout navLinks={normalLinks}>
            <div className={classNames.recordingPlaybackRoot}>
                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go(`/learner/index/${params.examId}`) }}>
                        <ArrowBackIosIcon />
                        <span style={{ paddingBottom: "0.2rem" }}>&nbsp;戻る</span>
                    </DefaultBackButton>
                </ButtonContainer>
                <AppBar position="static">
                    <Tabs value={state.tabIndex} onChange={(event, newValue) => {
                        //setTabData(newValue, state.pageIndex);
                        go(`/recording-playbacks/${params.examId}/${state.pageIndex}/${newValue}`);
                    }}>
                        <Tab label="本人" />
                        <Tab label="会場" />
                        <Tab label="受験" />
                    </Tabs>
                </AppBar>
                <Grid container spacing={3}>
                    {
                        state.data.map((x, index) => {
                            return (<LearnerCell
                                key={index}
                                src={x.src}
                                learner={x.learner}
                                tabIndex={state.tabIndex}
                                checkCheats={checkCheats}
                                cheats={cheats}
                                togglePlay={togglePlay}
                                time={sliderData.value}
                                isPlayng={sliderData.isPlayng}
                                setIsChangeCell={setIsChangeCell}
                            />);
                        })
                    }
                </Grid>
                <div
                    hidden={state.tabIndex == TAB_INDEX_PHOTO}
                    style={{ width: "100%" }}>
                    <Grid container style={{ width: "100%" }}
                        justify="space-between">
                        <Grid item>
                            <DefaultButton onClick={(e) => {
                                togglePlay(!sliderData.isPlayng);
                            }}>
                                {!sliderData.isPlayng ? "再生" : "一時停止"}
                            </DefaultButton>
                        </Grid>
                        <Grid item hidden={state.tabIndex != TAB_INDEX_RECORD}>
                            <Grid container
                                spacing={2}>
                                <Grid item>
                                    <VolumeDown />
                                </Grid>
                                <Grid item xs>
                                    <Slider
                                        style={{ width: "200px" }}
                                        max={100}
                                        value={sliderData.volume}
                                        valueLabelDisplay="on"
                                        onChange={(e, val) => {
                                            const newVolume = val as number;
                                            setSliderData((s) => {
                                                const videos = document.querySelectorAll(`video.${classNames.recordingPlayer}`);
                                                videos.forEach((x) => {
                                                    const v = x as HTMLVideoElement;
                                                    try {
                                                        v.volume = newVolume / 100;
                                                    } catch (err) {

                                                    }
                                                });
                                                return {
                                                    ...s,
                                                    volume: newVolume
                                                };
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <VolumeUp />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div>
                        &nbsp;
                    </div>
                    <Slider
                        style={{ width: "100%" }}
                        max={sliderData.max}
                        value={sliderData.value}
                        valueLabelDisplay="on"
                        valueLabelFormat={(val, index) => {
                            return <span>
                                {Math.floor(val)}
                            </span>;
                        }}
                        onChange={(e, val) => {
                            const newTime = val as number;
                            setSliderData((s) => {
                                const videos = document.querySelectorAll(`video.${classNames.recordingPlayer}`);
                                videos.forEach((x) => {
                                    const v = x as HTMLVideoElement;
                                    try {
                                        v.currentTime = newTime;
                                    } catch (err) {

                                    }
                                });
                                return {
                                    ...s,
                                    max: s.max,
                                    value: newTime
                                };
                            });
                        }}
                    />
                </div>

                <BottomNavigation
                    className={classNames.bottomNavigation}
                    onChange={(event, actionValue) => {
                        // 押されたボタンによって次の受験者の配列Indexを求める
                        let newIndex = state.pageIndex;
                        // page = 0, index = 0 -> 5
                        // page = 1, index = 6 -> 11
                        switch (actionValue) {
                            case "first":
                                newIndex = 0;
                                break;
                            case "prev":
                                newIndex = state.pageIndex - 1;
                                break;
                            case "next":
                                newIndex = state.pageIndex + 1;
                                break;
                            case "last":
                                newIndex = Math.ceil(examLearners.length / PAGE_SIZE) - 1;
                                break;
                            default:
                                break;
                        }
                        go(`/recording-playbacks/${params.examId}/${newIndex}/${Number(params.tabIndex)}`);
                    }}
                    showLabels
                >
                    <BottomNavigationAction
                        disabled={state.pageIndex <= 0}
                        icon={<FirstPageIcon />}
                        value="first" label="先頭" />
                    <BottomNavigationAction
                        disabled={state.pageIndex <= 0}
                        icon={<NavigateBeforeIcon />}
                        value="prev" label="前頁" />
                    <BottomNavigationAction
                        disabled
                        label={
                            "" + (state.pageIndex + 1)
                            + "/" + (Math.ceil(examLearners.length / PAGE_SIZE))} />
                    <BottomNavigationAction
                        disabled={state.pageIndex >= (Math.ceil(examLearners.length / PAGE_SIZE) - 1)}
                        icon={<NavigateNextIcon />}
                        value="next" label="後頁" />
                    <BottomNavigationAction
                        disabled={state.pageIndex >= (Math.ceil(examLearners.length / PAGE_SIZE) - 1)}
                        icon={<LastPageIcon />}
                        value="last" label="末尾" />
                </BottomNavigation>
                <ButtonContainer className={classNames.buttonStyle}>
                    <DefaultBackButton className="backBtn" onClick={() => { go(`/learner/index/${params.examId}`) }}>
                        <ArrowBackIosIcon />
                        <span style={{ paddingBottom: "0.2rem" }}>&nbsp;戻る</span>
                    </DefaultBackButton>
                </ButtonContainer>
            </div >
        </Layout >
    );

    function togglePlay(bPlay: boolean) {
        setSliderData((s) => {
            const videos = document.querySelectorAll(`video.${classNames.recordingPlayer}`);
            videos.forEach((x) => {
                const v = x as HTMLVideoElement;
                try {
                    if (!v || !v.src || v.readyState < 3) {
                        return;
                    }
                    if (bPlay) {
                        v.play();
                        v.currentTime = s.value;
                        v.volume = s.volume / 100;
                    } else {
                        v.pause();
                    }
                } catch (er) {
                    console.log(er);
                }
            });
            return {
                ...s,
                isPlayng: bPlay
            };
        });
    }
}

/**
 * 各受験者一人分のセル
 * @param param0 
 * @returns 
 */
function LearnerCell(
    { src, learner, tabIndex, checkCheats, cheats, togglePlay, time, isPlayng, setIsChangeCell }: {
        src: string | undefined,
        learner: Learner,
        tabIndex: number;
        checkCheats: CheckCheat[];
        cheats: Cheat[];
        togglePlay: (bPlay: boolean) => void;
        time: number;
        isPlayng: boolean;
        setIsChangeCell: (value: boolean) => void;
    }) {
    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api,  // API呼び出し
        getUser,
    } = common.useCommon();
    async function postCheckCheats(cheat: CheckCheat) {
        var res: APIResponse<CheckCheat> =
            await api("/api/o-learnerCheck", "POST", cheat);
        return res.value;
    }
    const classNames = useStyles();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [cheatCheckState, setCheatCheckState] = React.useState({
        cheatId: 0,
        adminUserId: "",
        noteText: "",
    });

    if (!cheatCheckState.adminUserId) {
        getUser().then((u) => {
            setCheatCheckState(x => {
                return {
                    ...x,
                    adminUserId: u.userName
                }
            })
        });
    }

    // 現在のタブで対象の不正疑惑項目
    const activeCheats = cheats.filter((x) => {
        return tabIndex == TAB_INDEX_PHOTO && x.extentionCode == "self_photo"
            || tabIndex == TAB_INDEX_ENV_RECORD && x.extentionCode == "env_record"
            || tabIndex == TAB_INDEX_RECORD && x.extentionCode == "record"
    });
    // 現在の不正疑惑ログで現在のタブの対象
    let maybeCheat = false;
    let hasCheat = false;
    const activeCheckCheats = checkCheats.filter(x => {
        if (x.userName != learner.userName) {
            return false;
        }
        const cheat = activeCheats.find(y => y.id == x.cheatId);
        if (cheat) {
            maybeCheat = true;
            if (cheat.cheatFlag) {
                hasCheat = true;
            }
        }
        return !!cheat;
    });
    let boxClassName = classNames.boxNoCheat;
    if (maybeCheat) {
        boxClassName = classNames.boxMaybeCheat;
    }
    if (hasCheat) {
        boxClassName = classNames.boxCheat;
    }
    boxClassName = src ? boxClassName : "";
    return (
        <Grid item xs={4}>
            <Paper variant="outlined"
                square onDoubleClick={(e) => {
                    if (!src) {
                        return;
                    }
                    togglePlay(false);
                    setIsModalOpen(true);
                }}>
                <div hidden={tabIndex != TAB_INDEX_PHOTO}>
                    <div
                        className={classNames.recordingPlayer + " " + boxClassName}>
                        <img
                            hidden={!src}
                            style={{
                                objectFit: "scale-down",
                                width: "100%",
                                height: "100%"
                            }}
                            src={src} />
                        <h2 hidden={!!src}>
                            まだ画像が保存されていません。
                        </h2>
                    </div>
                </div>
                <div hidden={tabIndex == TAB_INDEX_PHOTO}>
                    <video
                        playsInline
                        className={classNames.recordingPlayer + " " + boxClassName}
                        src={src}
                        hidden={!src}
                    ></video>
                    <h2
                        className={classNames.recordingPlayer + " " + boxClassName}
                        hidden={!!src}>
                        まだ動画が保存されていません。
                    </h2>
                </div>
                <h2 id="learnerNum">
                    受験番号：{function () {
                        try {
                            return learner.learnerNumber;
                        }
                        catch (err) {
                            return "";
                        }
                    }()}
                </h2>
            </Paper>

            <CustomModal
                openModal={isModalOpen}
                setOpenModal={() => {
                    setCheatCheckState({
                        cheatId: 0,
                        adminUserId: "",
                        noteText: "",
                    })
                    setIsModalOpen(false);
                }}
                modalFunc={() => {
                    if (cheatCheckState.cheatId === 0) return;
                    const c = {
                        executionId: learner.executionId,
                        userName: learner.userName,

                        cheatDatetime: Math.floor(time),
                        cheatId: cheatCheckState.cheatId,
                        adminUserId: cheatCheckState.adminUserId,
                        noteText: cheatCheckState.noteText,

                        // dummy
                        id: 0,
                        created: new Date().toISOString(),
                        modified: new Date().toISOString(),
                        deleteFlag: false
                    };
                    postCheckCheats(c);
                    setIsChangeCell(true);
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs>
                        <div>
                            報告
                        </div>
                        <div>
                            <FormControl component="fieldset">
                                <RadioGroup onChange={(e, value) => {
                                    setCheatCheckState(x => {
                                        return {
                                            ...x,
                                            cheatId: parseInt(value)
                                        };
                                    });
                                }}>
                                    {
                                        activeCheats.map((x, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={index}
                                                    value={x.id.toString()}
                                                    control={<Radio />}
                                                    label={x.name} />
                                            );
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div>
                            備考
                        </div>
                        <div>
                            <TextField
                                multiline
                                rowsMax={6}
                                rows={6}
                                onChange={(e) => {
                                    const noteText = e.target.value;
                                    setCheatCheckState(x => {
                                        return {
                                            ...x,
                                            noteText
                                        };
                                    });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </CustomModal>
        </Grid>
    );
}

