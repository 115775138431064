import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';



import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import { Localization } from "material-table";



import { Learner ,Exam } from '../../react-app-env';
import * as common from "../../common";




// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = makeStyles((theme) => ({
    learnerTableRoot: {
        fontSize: common.FONT_SIZE.mainText,
        "& .MuiTableCell-root": {
            fontSize: "1.2rem"
        },
        "& .MuiTableCell-head": {
            fontSize: "1.3rem"
        },
        "& .MuiTableCell-alignRight svg": {
            fontSize: "16px"
        },
        "& .MuiIconButton-label svg": {
            fontSize: "20px"
        },
        "& .MuiTableRow":{
            "&:hover": {
                backgroundColor: "rgba(245, 0, 87, 0.08)"
            },
            cursor: "pointer"
        },
        "& .MuiInputBase-input":{
            fontSize:common.FONT_SIZE.mainText
        }
    },
    columnName: {
        backgroundColor: "#7c7c80",
        opacity: 0.8,
        height: "60px"
    },
    columnNameStyle: {
        fontWeight: "bold",
        color: "#ffffff"
    },
    ordercolumnStyle: {
        fontWeight: "bold",
        color: "#ffffff",
        cursor: "pointer",
        "& span": {
            display: "inline-block",
            verticalAlign: "middle",
        }
    },
    checkBoxColumnStyle: {
        borderRadius: 0,
        backgroundColor: "white",
        padding: 0,
        marginLeft: 9
    },
    pageNation: {
        "& .MuiTypography-body2 ,.MuiTablePagination-input ,.MuiTablePagination-root": {
            fontSize: common.FONT_SIZE.tablePagenation
        }
    },
    contextMenu: {
        "& .MuiMenuItem-root": {
            fontSize: common.FONT_SIZE.tableBody,
        }
    },
    hoverRowStyle: {
        "&:hover": {
            backgroundColor: "rgba(245, 0, 87, 0.08)"
        },
        cursor: "pointer"
    }
}));

//material-tableの日本語化変数
const localizationData: Localization = {
    error: "エラー",
    body: {
      emptyDataSourceMessage: "表示するレコードがありません。",
      filterRow: {
        filterTooltip: "フィルター",
      },
      editRow: {
        saveTooltip: "保存",
        cancelTooltip: "キャンセル",
        deleteText: "この行を削除しますか？",
      },
      addTooltip: "追加",
      deleteTooltip: "削除",
      editTooltip: "編集",
    },
    header: {
      actions: "",
    },
    grouping: {
      groupedBy: "グループ化:",
      placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
      firstTooltip: "最初のページ",
      firstAriaLabel: "最初のページ",
      previousTooltip: "前のページ",
      previousAriaLabel: "前のページ",
      nextTooltip: "次のページ",
      nextAriaLabel: "次のページ",
      labelDisplayedRows: "{from}-{to} 全{count}件",
      labelRowsPerPage: "ページあたりの行数:",
      lastTooltip: "最後のページ",
      lastAriaLabel: "最後のページ",
      labelRowsSelect: "行",
    },
    toolbar: {
      addRemoveColumns: "列の追加、削除",
      nRowsSelected: "{0} 行選択",
      showColumnsTitle: "列の表示",
      showColumnsAriaLabel: "列の表示",
      exportTitle: "出力",
      exportAriaLabel: "出力",
      searchTooltip: "検索",
      searchPlaceholder: "受験番号検索",
    }
  };



const initialState = {
    mouseX: null,
    mouseY: null,
};

type orderFunc = (learnerArray: Learner[]) => Learner[]

interface Props {
    examData:Exam;
    learnerData: Learner[];
    resultNotification: any;
    getTotalPoints:any;
    isPassedLearner:any;
    learnerFilterForm: any;
    switchOrder: orderFunc,
    handleClickSortOrder: any,
    sortOrder: string,
}

interface tableRecord {
    leaenerNum: string;
    //isPassed:boolean;
    //points:number;
    isChecked:string;
    checkResult:string;
    checkUser:string;
    userName:string;
}




export function LearnerResultTable(props: Props) {
    // -----定数の定義-----
    const learnerData: Learner[] = props.learnerData ?? [];


    // -----共通関数の宣言-----
    const {
        api,
        params,
        go // 画面遷移 
    } = common.useCommon();




    // -----Handler-----

    const handleClose = () => {
        setState(initialState);
    };

    //テーブルを左クリック時の処理
    const handleNormalClick = (event: any, learner:tableRecord | undefined) => {
        if( event !== undefined ) event.preventDefault();
        setState(initialState);
        if ( learner !== undefined && selectedLearners.includes(learner.userName) ) {
            removeLearnerSelected(learner.userName);
            return;
        } else if(learner !== undefined){
            addLearnerSelected(learner.userName);
        }
    };

    //チェックボックスクリック時の処理
    const handleCheckboxClick =(selectedLearners:tableRecord[] ,learner:tableRecord | undefined)=>{
        const selectedLearnersArray = selectedLearners.map( learner => learner.userName );
        setSelectedLearners(selectedLearnersArray);
    }


    const addLearnerSelected =(userName:string)=>{
        const selectedLearnersArray = selectedLearners.concat();
        selectedLearnersArray.push(userName);
        setSelectedLearners(selectedLearnersArray);
    }


    const removeLearnerSelected =(userName:string)=>{
        const selectedLearnersArray = selectedLearners.concat();
        var val = userName
        var idx = selectedLearnersArray.indexOf(val);
        if(idx >= 0){
            selectedLearnersArray.splice(idx, 1); 
        }
        
        setSelectedLearners(selectedLearnersArray);
    }


    const handleClickContextMenu = (event:any ,learner:tableRecord)=>{
        if(!selectedLearners.includes(learner.userName)) addLearnerSelected(learner.userName);

        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        });

    }




    // -----汎用関数定義-----


    //引数の受験者IDのレコードが選択されているかどうか
    const isSelectedLearner = (userName:string)=>{
        if (selectedLearners.includes(userName)) {
            return true;
        } else {
            return false;
        }
    }

    //引数の受験者IDのLearnerデータを返す
    const getSelectedLearnerData = (userName:string)=>{
       const retValLearnerArray = learnerData.filter( (learner:Learner) => (learner.userName === userName)  );
       return retValLearnerArray[0];
    }



    const sortDateTime = (stringDateTimeA:string | null ,stringDateTimeB:string | null)=>{
        if( stringDateTimeA === null ) return -1;
        if( stringDateTimeB === null ) return 1;
        const dateTImeA = Date.parse(stringDateTimeA);
        const dateTImeB = Date.parse(stringDateTimeB);
        return dateTImeA - dateTImeB;
    }


    // -----スタイルの宣言-----
    const classNames = useStyles();





    // -----state-----
    const [state, setState] = React.useState<{
        mouseX: null | number;
        mouseY: null | number;
    }>(initialState);
    //選択データのlearneId
    const [selectedLearners, setSelectedLearners] = React.useState<string[]>([]);
    



    return (
        <div className={classNames.learnerTableRoot} >
           
            <MaterialTable
                icons={ {
                    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
                    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
                    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
                    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
                    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
                    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
                    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
                    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
                    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
                    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
                    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
                    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
                    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
                    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
                    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
                  }}
                columns={[
                    { title: '受験番号', field: 'leaenerNum', cellStyle: { fontWeight: "bold" }, type: "numeric", align: "left", customSort: () => props.handleClickSortOrder()/*defaultSort:"asc"*/},
                    // { title: '合否判定', field: 'isPassed' ,align:"left"},
                    // { title: '得点', field: 'points' ,type:"numeric" ,align:"left"},
                    { title: '録画管理', field: 'isChecked', align: "left", sorting: false, searchable: false },
                    { title: '不正判定', field: 'checkResult', align: "left", sorting: false, searchable: false },
                    { title: '確認者', field: 'checkUser', align: "left", sorting: false, searchable: false },

                ]}
                data={
                    props.switchOrder(learnerData).map((data, idx) => (
                    { 
                        leaenerNum: data.learnerNumber, 
                        //isPassed: data.executionStartDatetime === null ? "未受験" : (props.isPassedLearner(data.learnerNumber) ? "合格" : "不合格"),
                        //points: data.executionStartDatetime === null ? "" : props.getTotalPoints(data.learnerNumber),
                        isChecked: data.checkDate !== "" || data.checkDate !== null ? "録画済" : "未録画" ,
                        checkResult: data.checkResult,
                        checkUser: data.checkUser,
                        userName :data.userName,
                        tableData: { checked: isSelectedLearner(data.userName) }, 
                    }
                    ))
                }
                actions={[
                    /*{
                      icon: () => <MoreHorizIcon />,
                      tooltip: 'メニューを開く',
                      onClick: (event, rowData:any) => handleClickContextMenu(event,rowData),
                      position: "row"
                    },*/
                    {
                        icon: ()=> props.learnerFilterForm ,
                        isFreeAction: true,
                        onClick: () => {},
                    }
                ]}/*
                onSelectionChange={(selectedRecord,rowData) => {
                    handleCheckboxClick(selectedRecord,rowData);
                }}
                onRowClick={(event,rowData) => {
                    handleNormalClick(event,rowData);
                }}*/
                options={{
                    actionsColumnIndex: -1,
 
                    showTextRowsSelected:false,
                    showTitle: false,
                    //selection: true,
                    draggable: false,
                    headerStyle: {
                        backgroundColor: '#7c7c80',
                        color: 'white',
                        fontWeight:"bold"
                    }
                }}
                localization={localizationData}
            />

            {/*
            <Menu
                className={classNames.contextMenu}
                keepMounted
                open={state.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    state.mouseY !== null && state.mouseX !== null
                        ? { top: state.mouseY, left: state.mouseX }
                        : undefined
                }
            >
                {/* <MenuItem disabled={ selectedLearners.length > 1 } onClick={() => { go( "/test-results/learner/" + props.examData.id + "/" + selectedLearners[0] ) }}>試験結果詳細</MenuItem> */}
                {/*<MenuItem disabled onClick={() => { go( "/test-results/learner/" + props.examData.id + "/" + selectedLearners[0] ) }}>試験結果詳細</MenuItem>
                <MenuItem onClick={() => { props.resultNotification(selectedLearners) }}>選択中の受験者へ合否通知送信</MenuItem>
            </Menu>*/}


        </div>
    );
}
