import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Layout } from '../Layout';
import * as common from "../../common";
import { LearnerTable } from '../Table/LearnerTable';
import { createUseStyles } from 'react-jss';
import { Learner, APIResponse, ExamExtensions } from '../../react-app-env';
import { normalLinks, goExamRegister } from '../NavLinks/NavLinks';
import { Exam, APIArgsLearnerNotifyPost, learnerNotify, CheckCheat } from '../../react-app-env';
import { DefaultButton, ButtonContainer, DefaultBackButton } from '../StylesUI/CommonLayouts';
import { saveTextFile } from "../../common";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

// 引数で生成するCSSを定義してuseStyles関数を生成
const useStyles = createUseStyles({
    // 第一階層はCSSクラス名（実際は後ろに重複防止の接尾辞がつく）
    // homeRoot => .homeRoot-1-2-3 など
    learnerIndexRoot: {
        fontSize: common.FONT_SIZE.mainText,
        // 入れ子にする場合は＆を使う
        // https://cssinjs.org/jss-plugin-nested?v=v10.4.0
        "& h1": {
            color: "green"
        },
        "& .xxxxx": {
            color: "green"
        },
        "& h4": {
            color: "#003f71",
            fontWeight: "bold",
            marginBottom: "30px",
            fontSize: common.FONT_SIZE.title
        },
        "& .MuiOutlinedInput-notchedOutline": {
            height: "50px"
        },
        "& .MuiOutlinedInput-input": {
            padding: "15.5px 14px"
        },
        "& button": {
            fontSize: common.FONT_SIZE.button,
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& input , .mainTextSize, .MuiMenuItem-root": {
            fontSize: common.FONT_SIZE.mainText,
        }
    },
    upperContents: {
        display: "flex",
        justifyContent: "space-between"
    },
    learnerListContainer: {
        "& h5": {
            fontSize: common.FONT_SIZE.mainText,
            fontWeight: "bold",
            marginBottom: 0,
            textAlign: "Right",
            paddingRight: "19rem"
        },
        position: "relative"
    },
    csvButtonContainer: {
        display: "flex",
        justifyContent: "space-between"
    },
    csvContainer: {
        "& :hover": {
            color: "white",
            opacity: 1,
            textDecoration: "none"
        },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    buttonContainer: {
        marginBottom: "18px",
        display: "inline",
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        }
    },
    rowContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: 16
    },
    buttonStyle: {
        marginTop: "3.2rem",
        "& button:hover": {
            backgroundColor: "#003f71",
        },
        "& button:focus": {
            outline: "none !important",
        },
        "& .backBtn:hover": {
            backgroundColor: "#003f71",
            color: "white"
        },
        "& .csvButton": {
            marginRight: "1.6rem",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "22px"
        },
        "& #searchBtn": {
            minWidth: "7rem"
        }
    }
});



export interface transitLearnerValue {
    pathname: string,
    state: Learner
};



export const initialLearnerStateValue: Learner = {
    userName: "",
    executionId: 0,
    learnerNumber: "",
    email: "",
    acceptDatetime: "",
    noticeDatetime: "",
    executionStartDatetime: "",
    executionEndDatetime: "",
    checkDate: "",
    checkResult: "",
    modified: new Date(),
    checkUser: "",
    deleteFlag: false
};

const CODE_RECORD = "record";

export function LearnerIndex() {

    // -----共通関数の宣言-----
    const {
        params,
        go, // 画面遷移 
        api  // API呼び出し
    } = common.useCommon();




    // -----API-----
    // 受験者データを取得する
    function getLearnerData(args?: any): Promise<APIResponse<Learner[]>> {
        return api("/api/o-learner", "GET", args)
    }

    function getExamData(args?: any): Promise<APIResponse<Exam[]>> {
        return api("/api/o-exam", "GET", args)
    }

    function getExtensionData(args?: any): Promise<APIResponse<ExamExtensions>> {
        return api("/api/o-extension", "GET", args)
    }

    function postLearnerNotify(args?: APIArgsLearnerNotifyPost): Promise<APIResponse<learnerNotify>> {
        return api("/api/o-learnerNotify", "POST", args)
    }

    // 録画データを取得する
    function getMovieUrl(userName: string, isEnv = false, exists = true): Promise<APIResponse<{ exists: boolean }>> {
        return api("/api/o-storage", "GET", { userName, isEnv, exists });
    }

    // 不正データを取得する
    function getCheckCheats(executionId: number, userName: string): Promise<APIResponse<CheckCheat[]>> {
        return api("/api/o-learnerCheck", "GET", { executionId, userName });
    }

    // -----Handler-----

    /*
    const handleClickSortOrder = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
        e.currentTarget.blur();
        if(sortOrder === "ASC") setSortOrder("DESC");
        else setSortOrder("ASC");
    }*/

    // ソート可能な各列のソート
    const learnerNumSort = () => {
        learnerNumSortOrder === "ASC" ? setLearnerNumSortOrder("DESC") : setLearnerNumSortOrder("ASC");
        setAcceptSortOrder("null");
        setNoticeSortOrder("null");
        setExecuteDateSortOrder("null");
        setExecuteStartSortOrder("null");
        setExecuteEndSortOrder("null");
    }
    const acceptSort = () => {
        acceptSortOrder === "ASC" ? setAcceptSortOrder("DESC") : setAcceptSortOrder("ASC");
        setLearnerNumSortOrder("null");
        setNoticeSortOrder("null");
        setExecuteDateSortOrder("null");
        setExecuteStartSortOrder("null");
        setExecuteEndSortOrder("null");
    }
    const noticeSort = () => {
        noticeSortOrder === "ASC" ? setNoticeSortOrder("DESC") : setNoticeSortOrder("ASC");
        setLearnerNumSortOrder("null");
        setAcceptSortOrder("null");
        setExecuteDateSortOrder("null");
        setExecuteStartSortOrder("null");
        setExecuteEndSortOrder("null");
    }
    const executeDateSort = () => {
        executeDateSortOrder === "ASC" ? setExecuteDateSortOrder("DESC") : setExecuteDateSortOrder("ASC");
        setLearnerNumSortOrder("null");
        setAcceptSortOrder("null");
        setNoticeSortOrder("null");
        setExecuteStartSortOrder("null");
        setExecuteEndSortOrder("null");
    }
    const executeStartSort = () => {
        executeStartSortOrder === "ASC" ? setExecuteStartSortOrder("DESC") : setExecuteStartSortOrder("ASC");
        setLearnerNumSortOrder("null");
        setAcceptSortOrder("null");
        setNoticeSortOrder("null");
        setExecuteDateSortOrder("null");
        setExecuteEndSortOrder("null");
    }
    const executeEndSort = () => {
        executeEndSortOrder === "ASC" ? setExecuteEndSortOrder("DESC") : setExecuteEndSortOrder("ASC");
        setLearnerNumSortOrder("null");
        setAcceptSortOrder("null");
        setNoticeSortOrder("null");
        setExecuteDateSortOrder("null");
        setExecuteStartSortOrder("null");
    }


    // -----スタイルの宣言-----
    const classNames = useStyles();




    //------汎用関数の宣言-------
    //CSVファイルフォーマットファイルをダウンロード
    const getCSVFormat = () => {

        const header = [{
            "受験番号": "",
            "メールアドレス": ""
        }]

        common.toCsv(header)
            .then((result) => {

                saveTextFile(result, "CSVファイルフォーマット.csv");

            }).catch((err) => {
                alert(err);
            });

    }
    /*
    //降順ソート
    function descSortExam(learnerArray:Learner[]){
        return learnerArray.sort(function(a:Learner,b:Learner){
          const aNum = a.learnerNumber
          const bNum = b.learnerNumber
  
          if(aNum > bNum){
            return -1 ;
          }
          if(aNum < bNum){
            return 1 ;
          }
          return 0;
  
        })
  
    }
  
    //昇順ソート
    function ascSortExam(learnerArray:Learner[]){
      return learnerArray.sort(function(a:Learner,b:Learner){
        const aNum =  a.learnerNumber;
        const bNum =  b.learnerNumber;

        if(aNum < bNum){
          return -1 ;
        }
        if(aNum > bNum){
          return 1 ;
        }
        return 0;
  
      })
  
    }*/

    //受験者データの並び順を決める
    function switchOrder(learnerArray: Learner[]) {
        let retVal: Learner[] = [];

        if (learnerNumSortOrder !== "null") {
            if (learnerNumSortOrder === "DESC") {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    return a.learnerNumber.localeCompare(b.learnerNumber) * -1;
                });
            }
            else {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    return a.learnerNumber.localeCompare(b.learnerNumber);
                });
            }
        }
        else if (acceptSortOrder !== "null") {
            if (acceptSortOrder === "DESC") {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.acceptDatetime).getTime();
                    const bTime = new Date(b.acceptDatetime).getTime();
                    if (aTime > bTime) {
                        return -1;
                    }
                    if (aTime < bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.acceptDatetime).getTime();
                    const bTime = new Date(b.acceptDatetime).getTime();
                    if (aTime < bTime) {
                        return -1;
                    }
                    if (aTime > bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        else if (noticeSortOrder !== "null") {
            if (noticeSortOrder === "DESC") {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.noticeDatetime).getTime();
                    const bTime = new Date(b.noticeDatetime).getTime();
                    if (aTime > bTime) {
                        return -1;
                    }
                    if (aTime < bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.noticeDatetime).getTime();
                    const bTime = new Date(b.noticeDatetime).getTime();
                    if (aTime < bTime) {
                        return -1;
                    }
                    if (aTime > bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        else if (executeDateSortOrder !== "null") {
            if (executeDateSortOrder === "DESC") {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.executionStartDatetime).getTime();
                    const bTime = new Date(b.executionStartDatetime).getTime();
                    if (aTime > bTime) {
                        return -1;
                    }
                    if (aTime < bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.executionStartDatetime).getTime();
                    const bTime = new Date(b.executionStartDatetime).getTime();
                    if (aTime < bTime) {
                        return -1;
                    }
                    if (aTime > bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        else if (executeStartSortOrder !== "null") {
            if (executeStartSortOrder === "DESC") {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.executionStartDatetime).getTime();
                    const bTime = new Date(b.executionStartDatetime).getTime();
                    if (aTime > bTime) {
                        return -1;
                    }
                    if (aTime < bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.executionStartDatetime).getTime();
                    const bTime = new Date(b.executionStartDatetime).getTime();
                    if (aTime < bTime) {
                        return -1;
                    }
                    if (aTime > bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        else if (executeEndSortOrder !== "null") {
            if (executeEndSortOrder === "DESC") {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.executionEndDatetime).getTime();
                    const bTime = new Date(b.executionEndDatetime).getTime();
                    if (aTime > bTime) {
                        return -1;
                    }
                    if (aTime < bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
            else {
                retVal = learnerArray.sort(function (a: Learner, b: Learner) {
                    const aTime = new Date(a.executionEndDatetime).getTime();
                    const bTime = new Date(b.executionEndDatetime).getTime();
                    if (aTime < bTime) {
                        return -1;
                    }
                    if (aTime > bTime) {
                        return 1;
                    }
                    return 0;
                });
            }
        }
        else {
            retVal = learnerArray;
        }
        return retVal;
    }


    const goRegister = (url: string) => {
        const info = {
            pathname: url,
            state: initialLearnerStateValue
        };
        go(info);
    }



    const sendNotification = (learnerArgs: string[]) => {

        return postLearnerNotify({ executionId: executionId, learners: learnerArgs })
            .then((data) => {

                rerenderCompnent();
                return <div>
                    <p style={{ fontSize: "2.4rem", marginBottom: "2rem" }}>通知結果</p>
                    <p>成功数：{data.value.pass}</p>
                    <p>失敗数：{data.value.fail}</p>
                    <p>失敗受験者：{data.value.failLearners}</p>
                </div>

            }).catch((err) => {
                alert(err);
            });

    }

    //強制レンダリング
    const rerenderCompnent = () => {
        setUpdate(update ? false : true)
    }



    // -----state-----
    const [learnerStateValue, setLearnerStateValue] = React.useState<Learner[]>([initialLearnerStateValue]);
    const [learnerSearchedData, setLearnerSearchedData] = React.useState<Learner[]>([]);
    const [examName, setExamName] = React.useState("");
    const [executionId, setExecutionId] = React.useState(0);
    const [learnerMax, setLearnerMax] = React.useState(0);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [learnerNumSortOrder, setLearnerNumSortOrder] = React.useState<"ASC" | "DESC" | "null">("ASC");
    const [acceptSortOrder, setAcceptSortOrder] = React.useState<"ASC" | "DESC" | "null">("null");
    const [noticeSortOrder, setNoticeSortOrder] = React.useState<"ASC" | "DESC" | "null">("null");
    const [executeDateSortOrder, setExecuteDateSortOrder] = React.useState<"ASC" | "DESC" | "null">("null");
    const [executeStartSortOrder, setExecuteStartSortOrder] = React.useState<"ASC" | "DESC" | "null">("null");
    const [executeEndSortOrder, setExecuteEndSortOrder] = React.useState<"ASC" | "DESC" | "null">("null");

    const [update, setUpdate] = React.useState<boolean>(false)

    // -----use effefct-----
    // 初回だけ実行する処理
    React.useEffect(() => {
        // サーバーから認証してデータ取得
        getLearnerData({ executionId: params.examId })
            .then(async (data: APIResponse<Learner[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                // 試験の機能を取得（録画なしの場合に録画データへのアクセスを飛ばすため）
                let useRecord = false;
                await getExtensionData({ executionId: params.examId }).then((data: APIResponse<ExamExtensions>) => {
                    if (data.value && data.value.extensions && data.value.extensions.length > 0) {
                        useRecord = data.value.extensions.some(x => x.code === CODE_RECORD && x.parameter === 1);
                    }
                });

                let learners: Learner[] = [];
                if (data.value !== null) {
                    learners = data.value;
                    for (let learner of learners) {
                        if (learner.executionEndDatetime !== null) {
                            //受験済の場合は録画と不正の情報を受験者データに追加
                            if (useRecord) {
                                await getMovieUrl(learner.userName).then((data: APIResponse<{ exists: boolean }>) => {
                                    if (data.value !== null) learner.checkDate = data.value.exists ? "録画済" : "";
                                });
                            }
                            await getCheckCheats(learner.executionId, learner.userName).then((cheatData: APIResponse<CheckCheat[]>) => {
                                if (cheatData.value.length > 0) {
                                    learner.checkResult = "不正あり";
                                    learner.checkUser = cheatData.value.find(x => x.adminUserName !== null)?.adminUserName ?? "";
                                }
                            });
                        }
                    }
                }
                return learners;

            }).then((learners: Learner[] | undefined) => {
                if (learners && learners.length > 0) {
                    setLearnerStateValue(learners);
                    setLearnerSearchedData(learners);
                } else {
                    setLearnerStateValue([initialLearnerStateValue]);
                    setLearnerSearchedData([]);
                }
                setIsLoaded(true);

            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetLearner);
            });


        //試験名を取得
        getExamData({ id: params.examId })
            .then((data: APIResponse<Exam[]>) => {

                if (data.errorCode !== 20000) {
                    common.alertError(data.errorTitle, data.errorDetail);
                    return;
                }

                if (data.value !== null) {

                    setLearnerMax(data.value[0].learnerMax);
                    setExamName(data.value[0].name);
                    setExecutionId(data.value[0].id);

                }

            }).catch((err) => {
                alert(common.ResponseMessages.Error_GetExam);
            });
    }, [update]);

    React.useEffect(() => {
        // 初期表示時、スクロールバーを画面上部に配置
        window.scrollTo(0, 0);
    }, [])


    return (
        <Layout navLinks={normalLinks} buttons={
            [
                { text: goExamRegister.text, handler: () => { go({ pathname: goExamRegister.pathname, state: goExamRegister.state }) } }
            ]
        }>

            {isLoaded ?

                <div className={classNames.learnerIndexRoot}>

                    <div className={classNames.upperContents}>
                        <h4>{examName} 受験者一覧</h4>
                    </div>

                    <div className={classNames.learnerListContainer}>


                        <div className={classNames.csvButtonContainer}>

                            <ButtonContainer className={classNames.buttonStyle} style={{ marginBottom: "1.2rem" }}>
                                <DefaultBackButton className="backBtn" onClick={() => { go("/exam/index") }}><ArrowBackIosIcon /><span>&nbsp;戻る</span></DefaultBackButton>
                                {/*<DefaultButton  onClick={() => { learnerMax > learnerStateValue.length ? goRegister("/learner/register/"+params.examId+"/0") : alert("最大受験者数に達しています")}}><AddCircleOutlineIcon style={{fontSize:"1.8rem"}}/><span>&nbsp;新規登録</span></DefaultButton>*/}
                                <DefaultButton onClick={() => { learnerMax > (learnerStateValue[0].email !== '' ? learnerStateValue.length : 0) ? goRegister("/learner/register/" + params.examId + "/0") : alert("最大受験者数に達しています") }}><AddCircleOutlineIcon style={{ fontSize: "1.8rem" }} /><span>&nbsp;新規登録</span></DefaultButton>
                            </ButtonContainer>

                            <ButtonContainer className={classNames.buttonStyle} style={{ marginBottom: "1.2rem", minWidth: "71rem", marginLeft: "1.5rem" }}>
                                <DefaultButton className="csvButton" onClick={() => { go(`/recording-playbacks/${params.examId}/0/0`) }}><PlayCircleOutlineIcon /><span>&nbsp;録画再生</span></DefaultButton>
                                {/*<DefaultButton className="csvButton" onClick={() => { learnerMax > learnerStateValue.length ? go("/csv/upload/" + params.examId) : alert("最大受験者数に達しています") }}><CloudUploadIcon /><span>&nbsp;CSVアップロード</span></DefaultButton>*/}
                                <DefaultButton className="csvButton" onClick={() => { learnerMax > (learnerStateValue[0].email != '' ? learnerStateValue.length : 0) ? go("/csv/upload/" + params.examId) : alert("最大受験者数に達しています") }}><CloudUploadIcon /><span>&nbsp;CSVアップロード</span></DefaultButton>
                                <DefaultButton className="csvButton" onClick={() => { go("/csv/download/" + params.examId) }}><SaveIcon /><span>&nbsp;CSVダウンロード</span></DefaultButton>
                                <DefaultButton className="csvButton" onClick={getCSVFormat} style={{ marginRight: 0 }}>CSVフォーマットをダウンロード</DefaultButton>
                            </ButtonContainer>

                        </div>



                        <LearnerTable /*sortOrder={sortOrder} handleClickSortOrder={handleClickSortOrder}*/ switchOrder={switchOrder} learnerData={learnerSearchedData} examId={params.examId} examName={examName} sendNotification={sendNotification} rerenderCompnent={rerenderCompnent}
                            learnerNumSort={learnerNumSort} acceptSort={acceptSort} noticeSort={noticeSort} executeDateSort={executeDateSort} executeStartSort={executeStartSort} executeEndSort={executeEndSort}
                        />

                    </div>

                    <ButtonContainer className={classNames.buttonStyle} >
                        <DefaultBackButton className="backBtn" onClick={() => { go("/exam/index") }}><ArrowBackIosIcon /><span style={{ paddingBottom: "0.2rem" }}>&nbsp;戻る</span></DefaultBackButton>
                        {/*<DefaultButton  onClick={() => { learnerMax > learnerStateValue.length ? goRegister("/learner/register/"+params.examId+"/0") : alert("最大受験者数に達しています")}}><AddCircleOutlineIcon style={{fontSize:"1.8rem"}} /><span style={{paddingBottom:"0.2rem"}}>&nbsp;新規登録</span></DefaultButton>*/}
                        <DefaultButton onClick={() => { learnerMax > (learnerStateValue[0].email !== '' ? learnerStateValue.length : 0) ? goRegister("/learner/register/" + params.examId + "/0") : alert("最大受験者数に達しています") }}><AddCircleOutlineIcon style={{ fontSize: "1.8rem" }} /><span style={{ paddingBottom: "0.2rem" }}>&nbsp;新規登録</span></DefaultButton>
                    </ButtonContainer>

                </div>

                : <div>Loading...</div>

            }
        </Layout>
    );
}
